import { navigate } from "gatsby-link"
import React from "react"
import Layout from "../components/layout"

const SignUpPage = ({ location }) => {
  return (
    <Layout>
      <div className="min-h-screen gradient py-16">
        <h2 className="text-center">Instagram Influencer List Sample</h2>
        <p className="text-center font-sans">
          Fill in the form below and get a list sample of 100 instagam micro &
          nano influencers.
        </p>
        <form
          className="w-2/3 max-w-lg mx-auto shadow-custom p-8 rounded bg-white font-sans"
          name="request-sample"
          method="POST"
          //@ts-ignore
          data-netlify="true"
          action="/sample/"
        >
          <input
            type="hidden"
            className="hidden"
            name="form-name"
            value="request-sample"
          />
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Full Name"
                required
                name="name"
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Company <span className="lowercase">(if any)</span>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Job Title"
                name="company"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="email"
              >
                E-mail
              </label>
              <input
                required
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="text"
                placeholder="Email Address"
                name="email"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-plan"
              >
                I am
              </label>
              <div className="relative">
                <select
                  required
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-plan"
                  name="user"
                >
                  <option>a brand/business</option>
                  <option>an agency</option>
                  <option>a creator</option>
                  <option>an entrepreneur</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-plan"
              >
                Looking to
              </label>
              <div className="relative">
                <select
                  required
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-plan"
                  name="goal"
                >
                  <option>drive revenue</option>
                  <option>increase awareness</option>
                  <option>drive traffic</option>
                  <option>validate a product</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              I'm looking for influencers in
              <span className="ml-2 text-xs lowercase font-light tracking-normal">
                (this will help us improve our list)
              </span>
            </label>
            <div className="block w-full relative">
              <select
                name="influencer-category"
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="any">Any</option>
                <option value="fashion">Fashion</option>
                <option value="Gaming">Gaming</option>
                <option value="Health & Wellness">Health & Wellness</option>
                <option value="Food & Bevarage">Food & Bevarage</option>
                <option value="Lifestyle">Lifestyle</option>
                <option value="Travel">Travel</option>
                <option value="Makeup & Skincare">Makeup & Skincare</option>
                <option value="Education & Courses">Education & Courses</option>
                <option value="Sports & Fitness">Sports & Fitness</option>
                <option value="Stocks & Crypto">Stocks & Crypto</option>
                <option value="Pets & Animals">Pets & Animals</option>
                <option value="Business & entrepreneurship">
                  Business & Entrepreneurship
                </option>
                <option value="Web Services & Software">
                  Web Services & Software
                </option>
                <option value="Media & Entertainment">
                  Media & Entertainment
                </option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label className="block text-gray-500 font-bold mr-3 px-2 pt-2">
                <span className="text-sm">
                  We will send a copy of the list to your email
                </span>
              </label>
            </div>

            <input
              className="shadow mt-4 bg-dark-green hover:bg-light-green hover:text-black cursor-pointer focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
              value="Get List Sample"
            />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default SignUpPage
